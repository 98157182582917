'use client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.scss';
import { Languages, Language, i18nConfig } from '@/app/i18n/settings';
import 'node_modules/flag-icons/css/flag-icons.min.css';
import { usePathname, useRouter } from 'next/navigation';
import { logging } from '@/lib/logging/LogManager';
import { extractPath, logError } from '@/lib/logging/Logger';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import { useCurrentLocale } from 'next-i18n-router/client';

const logger = logging.getLogger('strapi', extractPath(__filename));

interface FlagIconProps {
  countryCode: string;
}

function FlagIcon({ countryCode = '' }: FlagIconProps) {
  if (countryCode === 'en') {
    countryCode = 'gb';
  }

  return <span className={`fi fis fiCircle inline-block mr-2 fi-${countryCode}`} />;
}

const LANGUAGE_SELECTOR_ID = 'language-selector';

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const locale = useCurrentLocale(i18nConfig);
  const languages = Languages;
  const [isOpen, setIsOpen] = useState(true);
  const selectedLanguage = languages.find(language => language.key === locale);
  const router = useRouter();
  const currentPathname = usePathname() || '';

  const handleLanguageChange = async (language: Language) => {
    const currentLocale = locale;
    await i18n.changeLanguage(language.key);
    // set cookie for next-i18n-router
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = date.toUTCString();
    document.cookie = `i18next=${language.key};expires=${expires};path=/`;

    if (currentLocale === i18nConfig.defaultLocale && language.key === i18nConfig.defaultLocale) {
      // Remove the current locale and the following slash (if present), only when switching to the default locale
      router.push(currentPathname.replace(new RegExp(`/${currentLocale}/?`), ''), {
        scroll: false,
      });
    } else {
      router.push(
        currentPathname.replace(new RegExp(`/${currentLocale}(?=$|/)`, 'g'), `/${language.key}`),
        { scroll: false }
      );
    }
    router.refresh();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleWindowClick = (event: any) => {
      const target = event.target.closest('button');
      if (target && target.id === LANGUAGE_SELECTOR_ID) {
        return;
      }
      setIsOpen(false);
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  if (!selectedLanguage) {
    return null;
  }

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button variant="bordered" onClick={() => setIsOpen(!isOpen)}>
            <FlagIcon countryCode={selectedLanguage.key} />
            {selectedLanguage.name}
          </Button>
        </DropdownTrigger>
        <DropdownMenu aria-label="Link Actions">
          {languages.map((language, index) => {
            return (
              <DropdownItem key={index}>
                <Button
                  isDisabled={locale === language.key}
                  onClick={() => handleLanguageChange(language)}
                  className="w-full"
                  //   className={`${
                  //     selectedLanguage.key === language.key
                  //       ? 'bg-gray-100 text-gray-900'
                  //       : 'text-gray-700'
                  //   } px-4 py-2 text-sm text-left items-center inline-flex hover:bg-gray-100 ${index % 2 === 0 ? 'rounded-r' : 'rounded-l'}`}
                  role="menuitem"
                >
                  <FlagIcon countryCode={language.key} />
                  <span className="truncate">{language.name}</span>
                </Button>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>

      {/* <div className="flex items-center z-40">
        <div className="relative inline-block text-left">
          <div>
            <Button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id={LANGUAGE_SELECTOR_ID}
              aria-haspopup="true"
              aria-expanded={isOpen}
            >
              <FlagIcon countryCode={selectedLanguage.key} />
              {selectedLanguage.name}
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </div>
          {isOpen && (
            <div
              className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="language-selector"
            >
              <div className="py-1 grid grid-cols-2 gap-2" role="none">
                {languages.map((language, index) => {
                  return (
                    <Button
                      isDisabled={i18n.language === language.key}
                      key={language.key}
                      onClick={() => handleLanguageChange(language)}
                      className={`${
                        selectedLanguage.key === language.key
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-700'
                      } px-4 py-2 text-sm text-left items-center inline-flex hover:bg-gray-100 ${index % 2 === 0 ? 'rounded-r' : 'rounded-l'}`}
                      role="menuitem"
                    >
                      <FlagIcon countryCode={language.key} />
                      <span className="truncate">{language.name}</span>
                    </Button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div> */}
    </>
  );
};
