'use client';
import { useError } from '@/lib/context/ErrorContextProvider.client';
import React from 'react';
import AlertBox from '../AlertBox';

export const ErrorOverlay: React.FC = () => {
  const { error, setError } = useError();

  let content;

  if (!error) return null;

  if (error instanceof Error) {
    content = (
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
        <p className="font-bold">Sorry, an error has occurred. Lottowatch will watch into it.</p>
        <p>
          <span className="font-semibold">Message:</span> {error.message}
        </p>
        <p>
          <span className="font-semibold">Stack Trace:</span>
        </p>
        <pre className="overflow-x-auto mt-2 bg-red-50 p-2 text-sm">{error.stack}</pre>
      </div>
    );
  } else {
    content = error;
  }
  return (
    <div className="fixed top-0 right-0 z-50">
      <AlertBox
        type="alert"
        timer={4000} // Omit for a persistent alert
        onDismiss={() => setError(null)} // Clears the error state when the alert is dismissed
      >
        {content}
      </AlertBox>
    </div>
  );
};
