'use client';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
// const lightTheme = createTheme({
//   type: "light",
// });
// const darkTheme = createTheme({
//   type: "dark",
//   theme: {
//     colors: {
//       gradient:
//         "linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)",
//     },
//   },
// });
import { Suspense } from 'react';

import { NextUIProvider } from '@nextui-org/react';
import { Loader } from '@/components/loading/LoadingOverlay.client';

export default function ProvidersWrapper({ children }: { children: React.ReactNode }) {
  // const { isBrowser } = useSSR();
  // useServerInsertedHTML(() => {
  //   return <>{CssBaseline.flush()}</>
  // })

  return (
    <NextThemesProvider
      defaultTheme="system"
      // attribute="class"
      storageKey="LightorDarkTheme"
      // value={{
      //   light: lightTheme.className,
      //   dark: darkTheme.className,
      // }}
    >
      {/* {isBrowser && <NextUIProvider>{children}</NextUIProvider>}  */}
      <NextUIProvider className="flex flex-col min-h-screen">
        <Suspense fallback={<Loader />}>
          {children}
          {/* <PorscheCookieConsent /> */}
        </Suspense>
      </NextUIProvider>
    </NextThemesProvider>
  );
}
