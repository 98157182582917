'use client';
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface PopupContextInterface {
  showPopup: boolean;
  togglePopup: () => void;
}

const PopupContext = createContext<PopupContextInterface | undefined>(undefined);

export const useCookieConsentPopup = (): PopupContextInterface => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
};

interface PopupProviderProps {
  children: ReactNode;
}

export const CookieConsentPopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <PopupContext.Provider value={{ showPopup, togglePopup }}>{children}</PopupContext.Provider>
  );
};
