'use client';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { push } from '@/app/MatomoCustomClientComponent';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionItem,
  Button,
  Checkbox,
  Divider,
  Link,
  Image,
} from '@nextui-org/react';
import { useCookieConsentPopup } from '@/lib/context/CookieConsentPopupProvider';

const CookieConsent = () => {
  const { t } = useTranslation();
  const { showPopup, togglePopup } = useCookieConsentPopup();
  const [acceptStatistics, setAcceptStatistics] = useState(false);
  const [acceptMarketing, setAcceptMarketing] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (!consent) {
      if (!showPopup) togglePopup();
    } else {
      const consentData = JSON.parse(consent);
      setAcceptStatistics(consentData.statistics);
      setAcceptMarketing(consentData.marketing);
    }
  }, []);

  const handleAccept = () => {
    setCookiesAccordingly(acceptStatistics, acceptMarketing, togglePopup);
  };

  const handleAcceptAll = () => {
    setAcceptStatistics(true);
    setCookiesAccordingly(true, acceptMarketing, togglePopup);
  };

  const handleAcceptNone = () => {
    setAcceptStatistics(false);
    setCookiesAccordingly(false, acceptMarketing, togglePopup);
  };

  if (!showPopup) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 bg-background bg-opacity-90 z-50 text-default-600">
      <div className="w-11/12 max-w-md sm:max-w-4xl bg-background rounded-lg overflow-auto p-6 max-h-[90vh] border-2 border-default">
        <div className="my-2 flex flex-row items-center justify-between">
          <h2 className="text-lg font-semibold mb-4">{t('cookies.cookieSettings')}</h2>
          <Image
            className="grayscale"
            isBlurred
            alt={t('cookies.altCookie')}
            src="/cookie-grey.png"
          />
        </div>
        <p>{t('cookies.cookieDescription')}</p>
        <Accordion
          variant="shadow"
          className="my-3"
          defaultExpandedKeys={Cookies.get('cookieConsent') ? ['content'] : undefined}
        >
          <AccordionItem
            title={t('cookies.settings')}
            textValue={t('cookies.cookieSettings')}
            key="content"
          >
            <div className="settings p-4 border border-gray-200 rounded bg-background text-default-600">
              <div className="mb-4">
                <div className="font-bold text-lg mb-2">{t('cookies.cookies')}</div>
                <Checkbox isDisabled defaultSelected>
                  {t('cookies.necessary')}
                </Checkbox>
                <p className="text-default-600">{t('cookies.necessaryCookies')}</p>
              </div>
              <Divider className="my-3" />
              <div className="mb-4">
                <Checkbox
                  disableAnimation
                  isSelected={acceptStatistics}
                  onChange={e => setAcceptStatistics(e.target.checked)}
                >
                  {t('cookies.statistics')}
                </Checkbox>
                <p className="text-default-600">{t('cookies.statisticsCookies')}</p>
              </div>
              {/* <Divider className="my-3" />
              <div className="mb-4">
                <Checkbox className="line-through" isSelected={acceptMarketing} isDisabled>
                  {t('cookies.marketing')}
                </Checkbox>
                <p className="text-default-600">{t('cookies.marketingCookies')}</p>
              </div> */}
              <Button className="mt-4" onClick={handleAccept}>
                {t('cookies.accept')}
              </Button>
            </div>
          </AccordionItem>
        </Accordion>
        <div className="flex justify-between">
          <Button className="mt-4" onClick={handleAcceptAll}>
            {t('cookies.acceptAll')}
          </Button>
          <Button className="mt-4" onClick={handleAcceptNone}>
            {t('cookies.rejectAll')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;

function setCookiesAccordingly(
  acceptStatistics: boolean,
  acceptMarketing: boolean,
  togglePopup: () => void
) {
  Cookies.set(
    'cookieConsent',
    JSON.stringify({ statistics: acceptStatistics, marketing: acceptMarketing }),
    { sameSite: 'lax', expires: 365 }
  );
  togglePopup();
  if (acceptStatistics) {
    push(['forgetUserOptOut']);
    push(['rememberCookieConsentGiven']);
    push(['trackPageView']);
  }
}
