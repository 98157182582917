'use client';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import { useCookieConsentPopup } from '@/lib/context/CookieConsentPopupProvider';

import { getFromCMS, reactMarkdownConfig } from '@/lib/utils/strapi';
import ReactMarkdown from 'react-markdown';
import { Button, Switch } from '@nextui-org/react';
import { MoonIcon, SunIcon } from '@/components/icons/Icons';
import { LanguageSelector } from '@/components/languageSelector/LanguageSelector';
import { useTheme as useNextTheme } from 'next-themes';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { togglePopup } = useCookieConsentPopup();
  const { theme, setTheme } = useNextTheme();
  const [themehelper, setThemehelper] = useState(true);
  const { t } = useTranslation();

  // useEffect(() => {
  //   setTheme(themehelper);
  // }, [setTheme, themehelper]);
  // const [info, setInfo] = useState('');

  // useEffect(() => {
  //   // declare the async data fetching function
  //   const fetchData = async () => {
  //     // get the data from the api
  //     const data = await getFromCMS('info-disclaimer');

  //     // set state with the result
  //     setInfo(data.attributes.text);
  //   };

  //   // call the function
  //   fetchData()
  //     // make sure to catch any error
  //     .catch(console.error);
  // }, []);

  /* Footer */
  return (
    <footer className="mt-10 border-t py-4 bg-default-50 h-full">
      <div className="flex flex-col max-[468px]:space-y-2 min-[468px]:flex-row justify-center space-x-2 sm:space-x-4 items-center">
        <Link className="text-blue-500 hover:underline lg:p-2.5 " href="/privacy">
          {t('privacy-policy')}
        </Link>
        <Link className="text-blue-500 hover:underline lg:p-2.5" href="/impress">
          {t('imprint')}
        </Link>
        {themehelper && (
          <Switch
            //   thumbIcon={<MoonIcon />}
            isSelected={theme === 'dark'}
            onValueChange={() => {
              console.log('CHANGE THEME');
              setTheme(theme === 'light' ? 'dark' : 'light');
            }}
            startContent={<MoonIcon />}
            endContent={<SunIcon />}
          />
        )}
        <LanguageSelector />
      </div>
      <div className="flex justify-center space-x-3 mt-3 items-end">
        <div className="p-2"></div>

        <Button variant="ghost" size="sm" onPress={() => togglePopup()}>
          {t('cookie-settings')}
        </Button>
        <div className="p-2 text-xs">© 2024</div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-xs my-5 mx-6 lg:mx-12">
          {/* <ReactMarkdown className="prose max-w-full " {...reactMarkdownConfig}>
            {info}
          </ReactMarkdown> */}
          {/* <span className="font-semibold"> Haftungsausschluss: </span>Die Inhalte auf dieser Website
          werden mit großer Sorgfalt erstellt. Für die Inhalte im Nutzerforum, die von den Nutzern
          selbst erstellt und eingereicht werden, kann der Anbieter jedoch keine Gewähr für die
          Richtigkeit, Vollständigkeit und Aktualität übernehmen. Die Nutzung der Inhalte der
          Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge im
          Forum geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters
          wieder. Mit der reinen Nutzung der Website des Anbieters kommt keinerlei
          Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande. Alle Angaben ohne
          Gewähr. */}
        </div>
      </div>
    </footer>
  );
};
