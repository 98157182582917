'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';

import Cookies from 'js-cookie';
import { logging } from '@/lib/logging/LogManager';

const logger = logging.getLogger('comp-matomo');

declare global {
  interface Window {
    _paq: any;
  }
}

const isExcludedUrl = (url: string, patterns: string[]): boolean => {
  let excluded = false;
  patterns.forEach(pattern => {
    if (new RegExp(pattern).exec(url) !== null) {
      excluded = true;
    }
  });
  return excluded;
};

interface InitSettings {
  url: string;
  siteId: string;
  jsTrackerFile?: string;
  phpTrackerFile?: string;
  excludeUrlsPatterns?: string[];
  onRouteChangeStart?: (path: string) => void;
  onRouteChangeComplete?: (path: string) => void;
  onInitialization?: () => void;
}

interface Dimensions {
  dimension1?: string;
  dimension2?: string;
  dimension3?: string;
  dimension4?: string;
  dimension5?: string;
  dimension6?: string;
  dimension7?: string;
  dimension8?: string;
  dimension9?: string;
  dimension10?: string;
}

// to push custom events
export function push(
  args: (Dimensions | number[] | string[] | number | string | null | undefined)[]
): void {
  if (!window._paq) {
    window._paq = [];
  }
  window._paq.push(args);
}

const trackingConsent = () => {
  const consent = Cookies.get('cookieConsent');
  if (!consent) return false;
  const consentData = JSON.parse(consent);
  return consentData.statistics;
};

function Tracker({
  url,
  siteId,
  jsTrackerFile = 'matomo.js',
  phpTrackerFile = 'matomo.php',
  excludeUrlsPatterns = [],
  onRouteChangeStart = undefined,
  onRouteChangeComplete = undefined,
  onInitialization = undefined,
}: InitSettings) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const prevPathRef = useRef(pathname);
  const hasMounted = useRef(false);

  useEffect(() => {
    // Initialization code that should only run once
    if (!hasMounted.current) {
      hasMounted.current = true;
      window._paq = window._paq !== null ? window._paq : [];
      if (!url) {
        logger.warn('Matomo disabled, please provide matomo url');
        return;
      }
      // order is important -_- so campaign are detected
      const excludedUrl =
        typeof window !== 'undefined' &&
        isExcludedUrl(window.location.pathname, excludeUrlsPatterns);

      if (onInitialization) onInitialization();
      const didConsent = trackingConsent(); //getCookieConsentValue('didConsent') === 'true';
      if (didConsent) {
        push(['forgetUserOptOut']);
        push(['rememberCookieConsentGiven']);
      } else {
        push(['optUserOut']);
        push(['forgetCookieConsentGiven']);
        push(['requireCookieConsent']);
      }
      push(['enableHeartBeatTimer']);
      push(['disableQueueRequest']);
      push(['enableLinkTracking']);
      push(['enableJSErrorTracking']);
      push(['setTrackerUrl', `${url}/${phpTrackerFile}`]);
      push(['setSiteId', siteId]);

      if (excludedUrl) {
        if (typeof window !== 'undefined') {
          logger.info(`matomo: exclude track ${window.location.pathname}`);
        }
      } else {
        push(['trackPageView']);
      }

      /**
       * for initial loading we use the location.pathname
       * as the first url visited.
       * Once user navigate across the site,
       * we rely on Router.pathname
       */

      // Not needed, when container is used

      const scriptElement = document.createElement('script');
      const refElement = document.getElementsByTagName('script')[0];
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.defer = true;
      scriptElement.src = `${url}/${jsTrackerFile}`;
      if (refElement.parentNode) {
        refElement.parentNode.insertBefore(scriptElement, refElement);
      }
    }
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (hasMounted.current) {
      const didConsent = trackingConsent(); //getCookieConsentValue('didConsent')) === 'true';
      //console.log('didConsent', didConsent, pathname, prevPathRef.current);
      if (!pathname || !didConsent) {
        return;
      }

      if (!prevPathRef.current) {
        prevPathRef.current = pathname;
        return;
      }

      push(['setReferrerUrl', `${prevPathRef.current}`]);
      push(['setCustomUrl', pathname]);
      push(['deleteCustomVariables', 'page']);
      prevPathRef.current = pathname;
      if (onRouteChangeStart) onRouteChangeStart(pathname);
      // In order to ensure that the page title had been updated,
      // we delayed pushing the tracking to the next tick.
      setTimeout(() => {
        push(['setDocumentTitle', document.title]);
        if (!!searchParams) {
          push(['trackSiteSearch', searchParams.get('keyword') ?? '']);
        } else {
          push(['trackPageView']);
        }
      }, 1);

      if (onRouteChangeComplete) onRouteChangeComplete(pathname);
    }
  }, [pathname, searchParams, , excludeUrlsPatterns, onRouteChangeComplete, onRouteChangeStart]);

  return null;
}

const MatomoTracker = (props: InitSettings) => {
  return (
    // <Suspense fallback={null}>
    <Tracker {...props} />
    // </Suspense>
  );
};

export default MatomoTracker;
