'use client';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Switch,
} from '@nextui-org/react';
import { useTheme as useNextTheme } from 'next-themes';

import { IconContainer } from '@/components/icons/IconsContainer';
import { LanguageSelector } from '@/components/languageSelector/LanguageSelector';
import { usePathname, useRouter, useSelectedLayoutSegment } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChevronDown,
  EmailIcon,
  FlatColorIconsButtingIn3,
  InfoIcon2,
  ManuallyIcon,
  MoonIcon,
  SunIcon,
} from '../components/icons/Icons';

const icons = {
  chevron: <ChevronDown fill="currentColor" size={16} />,
  email: <EmailIcon className="text-primary" fill="currentColor" size={30} />,
  manually: <ManuallyIcon className="text-secondary" fill="currentColor" size={30} />,
  infoIcon2: <InfoIcon2 className="text-primary" fill="currentColor" size={30} />,
};

interface MenuItem {
  name?: string;
  href?: string;
  description?: string;
  subItems?: MenuItem[];
  category?: string;
  icon?: React.ReactNode;
  onlyMobile?: boolean;
  segment?: string;
}

const currentYear = new Date().getFullYear();

let featureLLM_LottoMail: any = {};
let featureLLM_ScrapeMail: any = {};

if (process.env.NEXT_PUBLIC_FEATURE_LLM === 'true') {
  featureLLM_LottoMail = {
    name: 'Per Mail',
    href: '/lotto/mail',
    icon: icons.email,
    description: 'Sehen sie, ob sie gewonnen haben oder lassen sie sich benachrichtigen',
  };

  featureLLM_ScrapeMail = {
    name: 'Mail',
    href: '/scratch/mail',
    icon: icons.email,
    description:
      'Sehen sie, ob ihr Los schon einmal gespielt wurde und tragen sie zum Nummernpool bei',
  };
}

export default function Nav() {
  const router = useRouter();
  const segment = useSelectedLayoutSegment();
  const { theme, setTheme } = useNextTheme();
  const [themehelper, setThemehelper] = useState(true);
  // const isDark = themehelper === 'dark' ? true : false;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean | undefined>(false);
  // const locale = useCurrentLocale(i18nConfig);
  // console.log('Locale', locale);
  const { t } = useTranslation();

  const menuItems: MenuItem[] = [
    {
      name: 'Home',
      href: '/',
      segment: '',
    },
    {
      name: 'Services',
      href: '/#services',
      segment: '',
    },
    {
      name: 'Portfolio',
      href: '/#portfolio',
      segment: '',
    },
    {
      name: `${t('contact.title')}`,
      href: '/#contact',
      segment: '',
    },
    // {
    //   name: 'Services',
    //   segment: 'lotto',
    //   subItems: [
    //     ...(process.env.NEXT_PUBLIC_FEATURE_LLM === 'true' ? [featureLLM_LottoMail] : []),
    //     {
    //       name: 'Prüfen',
    //       description: 'Lottofelder ausfüllen und prüfen',
    //       href: '/#',
    //       icon: icons.manually,
    //     },
    //     {
    //       name: 'Alle Lottoziehungen',
    //       description: 'Von 1955 bis heute',
    //       href: `/lotto/draws/${currentYear}?page=1`,
    //       icon: icons.infoIcon2,
    //     },
    //   ],
    // },
    // {
    //   name: 'Rubbel',
    //   segment: 'scratch',
    //   subItems: [
    //     ...(process.env.NEXT_PUBLIC_FEATURE_LLM === 'true' ? [featureLLM_ScrapeMail] : []),
    //     {
    //       name: 'Prüfen',
    //       description: 'Prüfen sie ihr Rubbellos anhand der Losnummer',
    //       href: '/scratch/check',
    //       icon: icons.manually,
    //     },
    //   ],
    // },
    // { name: 'Community', href: '/posts', segment: 'posts' },
  ];

  // useEffect(() => {
  //   setTheme(themehelper);
  // }, [setTheme, themehelper]);

  return (
    <div className="container mx-auto">
      <Navbar
        position="sticky"
        maxWidth={'xl'}
        className="flex-auto grow-1 justify-between mt-6 sm:rounded"
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
      >
        <NavbarContent>
          <NavbarMenuToggle
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            className="sm:hidden"
            // onChange={(e) => setIsMenuOpen(isMenuOpen ? false : true)}
            // showIn="sm"
          />
          <NavbarBrand
            className="flex items-center cursor-pointer"
            onClick={() => {
              router.push('/');
            }}
          >
            <span className="mr-2 lg:mr-0">
              <IconContainer
                Icon={FlatColorIconsButtingIn3}
                iconProps={{
                  className: 'lg:border lg:rounded bg-white',
                }}
              />
            </span>
            {/* <div className="flex-shrink-0">
              <BetaIcon isDark={isDark} />
            </div> */}
            <div className="lg:ml-1">C. Zeltner</div>
            <div className="hidden lg:block text-sm">&nbsp;-&nbsp;</div>
            <div className="hidden lg:block text-sm">Software Design & Engineering</div>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="ml-16 hidden sm:flex text-sm" justify="center">
          {menuItems.map((item, idx) => {
            if (item.subItems) {
              return (
                <Dropdown key={idx}>
                  <NavbarItem>
                    <DropdownTrigger>
                      <Button
                        disableRipple
                        className={`p-0 bg-transparent data-[hover=true]:bg-transparent ${
                          segment === item.segment ? 'font-bold' : ''
                        }`}
                        endContent={icons.chevron}
                        radius="sm"
                        variant="light"
                      >
                        {item.name}
                      </Button>
                    </DropdownTrigger>
                  </NavbarItem>
                  <DropdownMenu
                    aria-label={item.name}
                    className="w-[340px]"
                    itemClasses={{
                      base: 'gap-4',
                    }}
                  >
                    <DropdownSection title={item.name}>
                      {item.subItems.map((subItem, idx) => {
                        return (
                          <DropdownItem
                            key={idx}
                            description={subItem.description}
                            textValue={subItem.name}
                            startContent={subItem.icon}
                            onPress={() => {
                              router.push(`${subItem.href}`);
                            }}
                            closeOnSelect
                          >
                            {subItem.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownSection>
                  </DropdownMenu>
                </Dropdown>
              );
            } else {
              return (
                <NavbarItem isActive={segment === item.segment} key={idx}>
                  <Link style={{ color: 'inherit' }} href={item.href!}>
                    {item.name}
                  </Link>
                </NavbarItem>
              );
            }
          })}
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-1" justify="end">
          {themehelper && (
            <Switch
              //   thumbIcon={<MoonIcon />}
              isSelected={theme === 'dark'}
              onValueChange={() => setTheme(theme === 'light' ? 'dark' : 'light')}
              startContent={<MoonIcon />}
              endContent={<SunIcon />}
            />
          )}
        </NavbarContent>

        <NavbarContent className="hidden md:flex gap-1" justify="end">
          <LanguageSelector />
        </NavbarContent>

        <NavbarMenu className="mt-7">
          {menuItems.map((item, idx) => {
            if (item.subItems) {
              return (
                <Dropdown key={idx}>
                  <NavbarMenuItem>
                    <DropdownTrigger>
                      <Button
                        disableRipple
                        className={`p-0 bg-transparent data-[hover=true]:bg-transparent ${
                          segment === item.segment ? 'font-bold' : ''
                        }`}
                        endContent={icons.chevron}
                        radius="sm"
                        variant="light"
                      >
                        {item.name}
                      </Button>
                    </DropdownTrigger>
                  </NavbarMenuItem>
                  <DropdownMenu
                    aria-label={item.name}
                    className="w-[340px]"
                    itemClasses={{
                      base: 'gap-4',
                    }}
                    // onAction={(key) => alert(key)}
                  >
                    <DropdownSection title={item.name}>
                      {item.subItems.map((subItem, idx) => {
                        return (
                          <DropdownItem
                            key={idx}
                            description={subItem.description}
                            startContent={subItem.icon}
                            onPress={() => {
                              setIsMenuOpen(!isMenuOpen);
                              router.push(`${subItem.href}`);
                            }}
                            closeOnSelect
                          >
                            {subItem.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownSection>
                  </DropdownMenu>
                </Dropdown>
              );
            } else {
              return (
                <NavbarMenuItem key={`${item.name}`}>
                  <Button // auto
                    variant="flat"
                    onPress={() => {
                      setIsMenuOpen(!isMenuOpen);
                      router.push(`${item.href}`);
                    }}
                  >
                    {item.name}
                  </Button>
                  {/* <Link
                    // color={
                    //   index === 2 ? "primary" : index === menuItems.length - 1 ? "danger" : "foreground"
                    // }
                    color="foreground"
                    className={`w-full ${segment === item.segment ? 'font-bold' : 'font-normal'}`}
                    href={item.href}
                    size="lg"
                  >
                    {item.name}
                  </Link> */}
                </NavbarMenuItem>

                // <NavbarMenuItem key={idx}>
                //   <Link
                //     style={{
                //       minWidth: '100%',
                //       color: 'inherit',
                //       fontWeight: segment === item.segment ? 'bold' : 'normal',
                //     }}
                //     href={item.href!}
                //     onClick={() => {
                //       setIsMenuOpen(false);
                //     }}
                //   >
                //     {item.name}
                //   </Link>
                // </NavbarMenuItem>
              );
            }
          })}
          <NavbarMenuItem key="themeswitcher-m">
            {themehelper && (
              <Switch
                //   thumbIcon={<MoonIcon />}
                isSelected={theme === 'dark'}
                onValueChange={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                startContent={<MoonIcon />}
                endContent={<SunIcon />}
              />
            )}
          </NavbarMenuItem>
          <NavbarMenuItem key="languageselector-m">
            <LanguageSelector />
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
    </div>
  );
}
