'use client';
import { useLoading } from '@/lib/context/LoadingContextProvider.client';
import { Spinner } from '@nextui-org/react';
import React from 'react';

export const LoadingOverlay: React.FC = () => {
  const { loading } = useLoading();

  if (!loading) return null;

  return <Loader />;
};

export function Loader(): React.ReactNode {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        zIndex: 1000,
      }}
    >
      <div className="fixed top-1/2 left-1/2 md:top-12 md:left-7 -translate-x-1/2 -translate-y-1/2 z-50">
        <Spinner size="lg" />
      </div>
    </div>
  );
}
